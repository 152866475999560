import {
  Alignments,
  Button,
  Icons,
  Modal,
  Sizes,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import SelectField from 'components/FormCmponents/SelectField';
import { Field, Formik, FormikErrors, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { useState } from 'react';
import { queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import ContractType from 'utils/StaticData/Contract/contractType.json';
import { Contract } from '../types';
import FranceContract from './FranceContract';

const TOTAL_STEPS = 2;
interface ContractProps {
  customerId: string;
}

const FRANCE_TOLL_ID = '6b81fa1a-36f8-4ef9-adef-6c1866c7451a';

const ContractNew: React.FC<ContractProps> = ({ customerId }) => {
  const [open, setOpen] = useState(false);
  const { errorDialog, successDialog } = useConfirmDialogs();
  const [step, setStep] = useState(1);

  const validate = (values: Contract) => {
    const errors: FormikErrors<Contract> = {};
    const mandatoryMsg = 'Please fill out this mandatory field';

    if (!values.tollID) {
      errors.tollID = mandatoryMsg;
    }
    if (!values.contractTypeID) {
      errors.contractTypeID = mandatoryMsg;
    }
    if (values.tollID === FRANCE_TOLL_ID && step === TOTAL_STEPS) {
      if (!values.offerValue) {
        errors.offerValue = mandatoryMsg;
      }
      if (!values.axxessInvoiceTypeOptionValue) {
        errors.axxessInvoiceTypeOptionValue = mandatoryMsg;
      }
    }
    return errors;
  };

  const handleOnStepClick = (num: number) => {
    setStep(num);
  };

  const handleOnClose = () => {
    setStep(1);
    setOpen(false);
  };

  const handleConfirm = (
    values: Contract,
    { setSubmitting }: FormikHelpers<Contract>
  ) => {
    customerInstance
      .post(customerEndPoints.saveContract, {
        customerID: customerId,
        ...values
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['customercontracts-list']
        });
        successDialog('', 'Successfully added new Contract');
        handleOnClose();
      })
      .catch((error) => {
        const message = 'Failed to create new contract, please try again later';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const submitButtonStyle = (values: Contract) => {
    const style: { [key: string]: string } = {};

    if (
      (values.tollID !== FRANCE_TOLL_ID && step === TOTAL_STEPS) ||
      (values.tollID === FRANCE_TOLL_ID && step === 1)
    ) {
      style.display = 'none';
    }

    return style;
  };

  return (
    <>
      <Button
        icon={<Icons.Add />}
        iconPosition="left"
        data-testid="add-new-contract"
        onClick={() => setOpen(true)}
      >
        New Contract
      </Button>
      {open && (
        <Formik<Contract>
          initialValues={{}}
          enableReinitialize
          validate={validate}
          onSubmit={handleConfirm}
        >
          {({ handleSubmit, values, isSubmitting }) => (
            <Modal
              title="New Contract"
              open={open}
              width={step === TOTAL_STEPS ? '70%' : '570px'}
              size={Sizes.Small}
              maskClosable={false}
              mask
              onClose={handleOnClose}
              contentScrollable
              actionsAlignment={Alignments.Right}
              centered
              actions={[
                {
                  label: 'back',
                  action: () => {
                    handleOnStepClick(step - 1);
                  },
                  props: {
                    variant: Variants.Outlined,
                    icon: <Icons.ArrowLeft />,
                    hidden: step === 1,
                    style: {
                      display: step === 1 ? 'none' : ''
                    }
                  }
                },
                {
                  label: 'Next',
                  action: () => {
                    handleOnStepClick(step + 1);
                  },
                  props: {
                    hidden:
                      values.tollID !== FRANCE_TOLL_ID || step === TOTAL_STEPS,
                    style: {
                      display:
                        values.tollID !== FRANCE_TOLL_ID || step === TOTAL_STEPS
                          ? 'none'
                          : ''
                    },
                    disabled: !!Object.keys(validate(values)).length,
                    name: 'next'
                  }
                },
                {
                  label: 'Submit',
                  action: () => {
                    handleSubmit();
                  },
                  props: {
                    icon: <Icons.Save />,
                    disabled:
                      !!Object.keys(validate(values)).length || isSubmitting,
                    hidden:
                      (values.tollID !== FRANCE_TOLL_ID &&
                        step === TOTAL_STEPS) ||
                      (values.tollID === FRANCE_TOLL_ID && step === 1),
                    style: submitButtonStyle(values),
                    name: 'submit'
                  }
                }
              ]}
            >
              {step === 1 && (
                <>
                  <Field
                    id="tollID"
                    name="tollID"
                    placeholder="Type here"
                    label="Toll"
                    labelPosition="left"
                    component={SelectField}
                    dataEndPoint="/toll"
                    dataField="tollId"
                    dataLabelField="name"
                    onOption
                    mandatory
                    getPopupContainer={() => document.body}
                  />
                  <Field
                    id="contractTypeID"
                    name="contractTypeID"
                    placeholder="Select"
                    label="Contract Type"
                    labelPosition="left"
                    component={SelectField}
                    options={ContractType.map((type) => ({
                      value: type.ContractTypeID,
                      label: type.LongName
                    }))}
                    mandatory
                    getPopupContainer={() => document.body}
                  />
                </>
              )}
              {step === TOTAL_STEPS && <FranceContract />}
            </Modal>
          )}
        </Formik>
      )}
    </>
  );
};

export default ContractNew;
